import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import _ from 'lodash';

import SeoHelmet from '../components/SeoHelmet';
import DefaultLayout from '../components/DefaultLayout';

const Title = styled.h1`
  margin: 48px 0;
  text-align: center;

  @media (max-width: 1023px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

const SectionTitle = styled.h2`
  margin: 48px 0 24px;

  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const Section = styled.div`
  position: relative;
  margin: 140px 0 72px;
`;

const TermsPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query TermsPageQuery {
      site {
        siteMetadata {
          siteName
          siteUrl
          emails {
            hello
          }
          links {
            privacy
            terms
          }
        }
      }
    }
  `);
  const siteName = _.get(data, 'site.siteMetadata.siteName');
  const siteUrl = _.get(data, 'site.siteMetadata.siteUrl');
  const privacyUrl = _.get(data, 'site.siteMetadata.links.privacy');
  const termsUrl = _.get(data, 'site.siteMetadata.links.terms');
  const helloEmail = _.get(data, 'site.siteMetadata.emails.hello');

  return (
    <DefaultLayout>
      <SeoHelmet
        location={location}
        title="Пользовательское соглашение"
        description={`Предмет соглашения, порядок регистрации и получения доступа к Курсу ${siteName} и ответсвенности сторон`}
      />
      <Section>
        <Title>Пользовательское соглашение</Title>
        <p>
          Настоящее Пользовательское соглашение (далее — Соглашение) в
          соответствии с положениями ст. 437 ГК РФ является офертой Гурова
          Германа Георгиевича (далее — Администратор), регулирует отношения
          Пользователя и Оператора сайта <a href={siteUrl}>{siteUrl}</a> (далее
          — Сайт), правообладателем которого является Гуров Герман Георгиевич, и
          адресовано любому дееспособному лицу (далее – Пользователю) на
          изложенных ниже условиях.
        </p>
        <p>
          Безусловным и безоговорочным принятием (акцептом) Пользователем
          условий данного Соглашения является регистрация и/или авторизация
          через аккаунт Пользователя в социальной сети.
        </p>
        <SectionTitle>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</SectionTitle>
        <p>
          1.1. В настоящем Соглашении, если из его текста прямо не вытекает
          иное, следующие термины будут иметь указанные ниже значения:
        </p>
        <p>
          <b>Аккаунт</b> — учетная запись Пользователя на Сайте Администратора;
        </p>
        <p>
          <b>Курс {siteName}</b> — совокупность информационных материалов,
          оформленных в виде веб-страниц, включающие в себя авторский текстовый
          контент, изображения, презентации, видео- и аудиоматериалы,
          графические элементы и программ для ЭВМ (программные средства для
          выполнения тестовых заданий) представляющие собой интерактивную
          платформу {siteName}. Курс {siteName} не является образовательной
          программой, а Администратор не является организацией, осуществляющей
          образовательную деятельность;
        </p>
        <p>
          <b>Личный кабинет</b> — совокупность защищенных страниц Сайта,
          созданных в результате регистрации Пользователя, используя которые
          Пользователь имеет возможность получения доступа к обучению по Курсу{' '}
          {siteName}, возможность получения информации о нем, возможность
          изменения сведений о Пользователе, а также осуществления иных
          действий, предусмотренных явными функциями Личного кабинета. Доступ в
          Личный кабинет осуществляется путем внесения учетных данных в
          предусмотренные для этого поля на Сайте;
        </p>
        <p>
          <b>Пользователь</b> — лицо, заключившее с Администратором настоящее
          Соглашение путем акцепта оферты, расположенной в сети Интернет по
          сетевому адресу <a href={termsUrl}>{termsUrl}</a>;
        </p>
        <p>
          <b>Сайт</b> — совокупность информации, авторских текстов, графических
          элементов, дизайна, изображений, фото, аудио и видеоматериалов, и иных
          охраняемых результатов интеллектуальной деятельности и средств
          индивидуализации Администратора, а также программ для ЭВМ,
          содержащихся в информационной системе, обеспечивающей доступность
          такой информации в сети Интернет по сетевому адресу{' '}
          <a href={siteUrl}>{siteUrl}</a>. Сайт является Интернет-ресурсом,
          предназначенным для предоставления Пользователю информационных
          материалов с целью организации самообучения Пользователя навыкам по
          проектированию, разработке и масштабированию веб-сервисов;
        </p>
        <p>
          <b>Соглашение</b> — настоящее пользовательское соглашение;
        </p>
        <p>
          <b>Стороны</b> — Администратор и Пользователь.
        </p>
        <SectionTitle>2. ПРЕДМЕТ СОГЛАШЕНИЯ</SectionTitle>
        <p>
          2.1. Администратор предоставляет Пользователю право использовать Сайт,
          Личный кабинет по их прямому назначению, в том числе путем
          осуществления доступа к Сайту, Личному кабинету с помощью персональных
          компьютеров и мобильных устройств и использования явных функций Сайта,
          Личного кабинета на условиях безвозмездной неисключительной лицензии
          на территории доступа к Сайту, Личному кабинету, на срок, в течение
          которого Сайт, Личный кабинет остаются доступны для Пользователя.
        </p>
        <p>
          2.2. Администратор оказывает Пользователю услуги по предоставлению
          доступа к Сайту, Курсу {siteName} с целью организации самообучения
          Пользователя навыкам по проектированию, разработке и масштабированию
          веб-сервисов.
        </p>
        <SectionTitle>3. ПОРЯДОК РЕГИСТРАЦИИ НА САЙТЕ</SectionTitle>
        <p>
          3.1. Администратор предоставляет Пользователю до его регистрации на
          Сайте доступ к информации о Сайте, Курсе {siteName}, размещенным на
          сайте по сетевому адресу <a href={siteUrl}>{siteUrl}</a>.
        </p>
        <p>
          3.2. После того, как Пользователь заполнил все необходимые поля формы
          регистрации на Сайте по сетевому адресу{' '}
          <a href={siteUrl}>{siteUrl}</a>, регистрация считается завершенной.
        </p>
        <p>
          3.3. По завершении регистрации на Сайте Пользователю предоставляется
          доступ к Личному кабинету по учетным данным – адресу электронной почты
          и паролю. Пароль может быть изменен Пользователем в любое время после
          регистрации.
        </p>
        <p>
          3.4. На Пользователе лежит обязанность обеспечить безопасность и
          сохранность пароля. При утрате либо компрометации пароля, а также в
          случае незаконного завладения третьими лицами доступа к Личному
          кабинету, Пользователь обязан незамедлительно сообщить об этом
          Администратору на адрес электронной почты {helloEmail}. До момента
          поступления такого сообщения все действия, совершенные с
          использованием Личного кабинета Пользователя, будут считаться
          совершенными самим Пользователем.
        </p>
        <SectionTitle>
          4. ПОРЯДОК ПОЛЬЗОВАНИЯ ЛИЧНЫМ КАБИНЕТОМ И САЙТОМ
        </SectionTitle>
        <p>4.1. По завершении регистрации Пользователь получает доступ к:</p>
        <p>
          4.1.1. Личному кабинету. В Личном кабинете Пользователя отображается
          информация о начатых и завершенных Пользователем разделах Курса
          {siteName};
        </p>
        <p>
          4.1.2. возможности включения Пользователя в виртуальную группу в сети
          Интернет на платформе Facebook для обсуждения возникающих вопросов.
        </p>
        <p>
          4.2. Администратор вправе в любое время по своему усмотрению изменять
          темы отдельных разделов Курса {siteName}, менять содержание,
          количество, наименование и вид материалов, входящих в его состав.
          Информация о таких изменениях публикуется на Сайте.
        </p>
        <p>
          4.3. Пользователь обязан пользоваться Сайтом и Личным кабинетом
          добросовестно, не нарушая законодательство Российской Федерации, права
          и свободы третьих лиц, нормы морали и нравственности.
        </p>
        <SectionTitle>5. УСЛОВИЯ И ПОРЯДОК ДОСТУПА К КУРСУ NERDIA</SectionTitle>
        <p>
          5.1. Пользователю доступна информация о Курсе {siteName} и его
          описание, о его приблизительной продолжительности, о материалах
          (аудиовизуальных, текстовых, графических) в его составе.
        </p>
        <p>
          5.2. Доступ к Курсу {siteName} предоставляется Пользователям,
          прошедшим регистрацию. Доступ предоставляется через Личный кабинет.
        </p>
        <p>
          5.3. Курс {siteName} является симулятором работы в IT-компании и
          предусматривает практические и теоретические материалы, а также
          программные средства в виде теста для проверки Пользователем усвоенной
          информации. Тестирование происходит в автоматическом режиме
          программными средствами Сайта.
        </p>
        <p>
          5.4. По завершению курса Пользователь вправе оценить курс и оставить
          комментарий о курсе. Администратор оставляет за собой право удалить
          комментарии Пользователей, признанные Администратором противоречащими
          законодательству Российской Федерации.
        </p>
        <SectionTitle>6. ФИНАНСОВЫЕ УСЛОВИЯ</SectionTitle>
        <p>
          6.1. Администратор вправе оказывать Пользователю любые услуги без
          взимания отдельной платы.
        </p>
        <SectionTitle>7. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</SectionTitle>
        <p>
          7.1. Используя Сайт, Пользователь признает и соглашается с тем, что
          все без изъятий содержимое Сайта (в том числе, но не ограничиваясь:
          аудиовизуальные произведения, текстовые и графические материалы,
          программы для ЭВМ, товарные знаки/знаки обслуживания, логотипы),
          структура содержимого Сайта, программный код Сайта и/или его частей
          являются результатами интеллектуальной деятельности, исключительное
          право на которые в полном объеме принадлежит Администратору.
          Исключительное право, а также иные интеллектуальные права (если
          применимо) на вышеуказанные результаты интеллектуальной деятельности
          не переходят к Пользователю в результате пользования Сайтом и
          заключения Соглашения.
        </p>
        <p>
          7.2. Пользователю запрещается копировать, модифицировать, изменять,
          удалять, дополнять, публиковать, передавать содержащиеся на Сайте
          результаты интеллектуальной деятельности, создавать производные
          работы, изготавливать или продавать товары/оказывать услуги на их
          основе, воспроизводить, отображать или любым другим образом
          эксплуатировать или использовать такие результаты интеллектуальной
          деятельности без прямого разрешения Администратора. При цитировании
          материалов Сайта, если это прямо предусмотрено функциями Сайта,
          Пользователь обязуется указывать ссылку на Сайт.
        </p>
        <SectionTitle>8. ОГРАНИЧЕНИЯ ПРИ ПОЛЬЗОВАНИИ САЙТОМ</SectionTitle>
        <p>8.1. Во избежание сомнений, Пользователю запрещается:</p>
        <p>
          8.1.1. копировать и/или распространять какую-либо информацию (включая
          части и компоненты Курса {siteName}, логотипов и товарных
          знаков/знаков обслуживания), полученную на Сайте, кроме случаев, когда
          такая функция прямо предусмотрена на Сайте;
        </p>
        <p>
          8.1.2. использовать информацию, полученную на Сайте, для осуществления
          коммерческой деятельности, извлечения прибыли, либо для использования
          любым противоречащим закону способом, за исключением навыков,
          приобретенных на основе полученной в соответствии с Соглашением
          информации;
        </p>
        <p>
          8.1.3. копировать, либо иным способом использовать программную часть
          (программный код или часть кода) Сайта, а также его дизайн;
        </p>
        <p>
          8.1.4. передавать третьим лицам доступ к личному кабинету, а также к
          материалам (аудиовизуальных, текстовых, графических) Курса {siteName};
        </p>
        <p>
          8.1.5. изменять каким бы то ни было способом программную часть Сайта,
          совершать действия, направленные на изменение функционирования и
          работоспособности Сайта;
        </p>
        <p>
          8.1.6. размещать на Сайте персональные данные третьих лиц без их
          согласия, в том числе, но не ограничиваясь: домашние адреса, телефоны,
          паспортные данные, адреса электронной почты;
        </p>
        <p>
          8.1.7. размещать на Сайте коммерческую рекламу, коммерческие
          предложения, агитационную информацию и любую другую навязчивую
          информацию;
        </p>
        <p>
          8.1.8. оскорблять и иным образом нарушать права и свободы других
          пользователей Сайта, третьих лиц, а также групп лиц;
        </p>
        <p>
          8.1.9. использовать нецензурную брань, осуществлять либо
          распространять информацию, содержащую призывы к массовым беспорядкам,
          осуществлению экстремистской деятельности, участию в массовых
          (публичных) мероприятиях, проводимых с нарушением установленного
          порядка, распространять информацию, необходимую для получения
          результатов интеллектуальной деятельности.
        </p>
        <SectionTitle>9. ПЕРСОНАЛЬНЫЕ ДАННЫЕ</SectionTitle>
        <p>
          9.1. Цель, порядок, сроки и иные существенные условия, при которых
          Администратор обрабатывает персональные данные Пользователей,
          определены в Политике конфиденциальности и обработки персональных
          данных, расположенной по сетевому адресу{' '}
          <a href={privacyUrl}>{privacyUrl}</a>.
        </p>
        <SectionTitle>10. ОТВЕТСТВЕННОСТЬ</SectionTitle>
        <p>
          10.1. В случае нарушения Пользователем условий Соглашения,
          законодательства Российской Федерации, норм морали и нравственности,
          либо технических требований Администратор вправе заблокировать или
          удалить Личный кабинет, запретить либо ограничить доступ по учетным
          данным Пользователя к определенным или всем функциям Сайта, включая
          Курс {siteName} и учебные материалы.
        </p>
        <p>
          10.2. При выявлении случаев предоставления Пользователем доступа к
          Личному кабинету третьим лицам, Администратор по своему выбору
          применяет меры, предусмотренные пунктом 10.1. Соглашения. При этом
          Администратор вправе полностью заблокировать доступ Пользователя к
          Личному кабинету и Курсу {siteName}.
        </p>
        <p>
          10.3. Нарушение Пользователем условий Соглашения, повлекшее
          неблагоприятные последствия для Администратора (ущерб,
          административная и иная ответственность, предупреждения
          правоохранительных и иных органов исполнительной власти, претензии
          третьих лиц), является основанием для Администратора прекратить доступ
          Пользователя к Личному кабинету и Курсу {siteName}.
        </p>
        <p>
          10.4. В случае нарушения Пользователем интеллектуальных прав
          Администратора, а также ограничений по использованию Сайта, указанных
          в разделах 7 и 8 Соглашения, Администратор вправе в любой момент без
          предупреждения заблокировать такому Пользователю полностью или
          частично доступ к Личному кабинету и Курсу {siteName}, а также, по
          своему усмотрению, обратиться к соответствующему Пользователю с
          требованием об устранении нарушений и/или потребовать устранить
          нарушения в судебном порядке.
        </p>
        <p>
          10.5. Администратор не отвечает за работоспособность Сайта и не
          гарантирует его бесперебойной работы. Администратор также не
          гарантирует сохранности информации, размещенной на Сайте и возможности
          бесперебойного доступа к Курсу {siteName}.
        </p>
        <p>
          10.6. Пользователь использует Сайт в том виде, в каком он представлен,
          на собственный риск. Администратор не гарантирует Пользователю
          достижения каких-либо результатов вследствие использования Сайта.
        </p>
        <p>
          10.7. Сайт может содержать ссылки на другие сайты в сети Интернет
          (сайты третьих лиц). Указанные третьи лица и содержание их
          сайтов/контента не проверяются Администратором на соответствие тем или
          иным требованиям (достоверности, полноты, законности и т.п.).
          Администратор не несет ответственности за любую информацию, материалы,
          размещенные на сайтах третьих лиц, к которым Пользователь получает
          доступ с использованием Сайта, в том числе, за любые мнения или
          утверждения, выраженные на сайтах третьих лиц, рекламу и т.п., а также
          за доступность и работоспособность таких сайтов или контента и
          последствия их использования Пользователем.
        </p>
        <p>
          10.8. Администрация не несет ответственности за любые прямые или
          непрямые убытки, произошедшие из-за использования и/или невозможности
          использования Сайта.
        </p>
        <SectionTitle>11. ИЗМЕНЕНИЕ УСЛОВИЙ СОГЛАШЕНИЯ</SectionTitle>
        <p>
          11.1. Администратор вправе в любой момент по своему усмотрению в
          одностороннем порядке изменять условия Соглашения, при этом такие
          изменения вступают в силу в момент опубликования новой версии
          Соглашения на Сайте.
        </p>
        <p>
          11.2. При каждом последующем посещении Сайта до начала пользования
          Личным кабинетом и/или иными функциональными возможностями Сайта
          Пользователь обязуется ознакомиться с новой версией Соглашения.
          Продолжение использования Сайта и Личного кабинета будет означать
          согласие Пользователя с условиями новой версии Соглашения.
        </p>
        <SectionTitle>12. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</SectionTitle>
        <p>
          12.1. Соглашение и все возникающие из него правоотношения регулируются
          законодательством Российской Федерации с учетом его коллизионных норм.
          Все возникающие споры разрешаются в соответствии с законодательством
          Российской Федерации.
        </p>
        <p>
          12.2. Признание судом какого-либо положения Соглашения
          недействительным или не подлежащим принудительному исполнению не
          влечет недействительности иных положений Соглашения.
        </p>
        <p>
          12.3. Бездействие со стороны Администратора в случае нарушения
          кем-либо из Пользователей положений Соглашения не лишает
          Администратора права предпринять позднее соответствующие действия в
          защиту своих интересов и защиту интеллектуальных прав на охраняемые в
          соответствии с законодательством результаты интеллектуальной
          деятельности, размещенные на Сайте.
        </p>
        <p>
          12.4. Пользователь подтверждает, что ознакомился со всеми положениями
          Соглашения, понимает и принимает их.
        </p>
      </Section>
    </DefaultLayout>
  );
};

export default TermsPage;
